<template lang="pug">
  article.collection-item-thumb.px-10.md_px-20.transition.duration-200.delay-150(:class="{'w-1x2 md_w-4x12': isLnd, 'w-1x2 md_w-3x12': !isLnd, 'opacity-0': !loaded}")
    h6.text-10.xl_text-12.mb-5.md_mb-8.md_min-h-lh-2x
      router-link(:to="{name: 'collection-item', params: {collectionItem: entry.slug}}")
        | {{ entry.artist }}
        <span class="hidden md_inline">, <i>{{ entry.title }}</i>, {{ entry.year }}</span>
        p(v-if="$route.hash === '#debug'")
          | {{ image.courtesy }}
          | {{ image.photoCredit }}
    router-link.block.cursor-pointer.overflow-hidden(tag="figure", :to="{name: 'collection-item', params: {collectionItem: entry.slug}}")
      resp-img.block.transform.md_hover_scale-103.transition.duration-500(:image="image", @load="onImgLoad")
      //- loading sizer
      .w-full.pb-ar-4x3(v-if="!loaded")
</template>

<script>
export default {
  name: 'CollectionItemThumb',
  props: ['entry'],
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    image () {
      const customIndexImage = this.entry.indexImage && this.entry.indexImage[0]
      const firstSlideImage = this.entry.slides[0]?.image[0]
      const artlogicMainImg = { url: this.entry.mainImageUrlSmall, altText: `${this.entry.artist}, ${this.entry.title}` }
      return customIndexImage || firstSlideImage || artlogicMainImg
    },
    isLnd () {
      return this.image?.width && this.image?.height && this.image.width > this.image.height
    }
  },
  methods: {
    onImgLoad (e) {
      this.loaded = true
    }
  }
}
</script>

<style>
</style>
