<template lang="pug">
  main-el.search.pb-40.md_pb-310
    container-panel-active
      header.pt-40.px-20.md_px-40.-mb-20
        h1.text-20.xl_text-24
          template(v-if="!pages") Searching...
          template(v-else) Search results for '{{ term }}'

      section(v-if="pages")
        //- pages...
        template(v-for="page in pages")
          //- sections...
          template(v-for="section in sections")
            //- (has results)
            template(v-if="page[section].length")
              h2.mt-60.md_mt-100.text-20.xl_text-24.capitalize.px-20.md_px-40 {{ section }}
              ul.mt-50.flex.flex-wrap.entries-small-alternate(:class="{'px-10 md_px-20': section === 'collection'}")
                //- entries...
                template(v-for="entry in page[section]")
                  //- (entry: programme)
                  template(v-if="isProgram(entry)")
                    programme-entry-thumb.md_w-1x2(:entry="entry", size="small")
                  //- (entry: colleciton)
                  template(v-if="entry.section === 'collection'")
                    collection-item-thumb.mb-24.lg_mb-60(:entry="entry")

              //- .px-40(v-if="page[section].length > 4")
                btn-read-more(before="View", :after="' Results'", @click="")
</template>

<script>
import { api } from '@/store'
import { programmeEntryItem } from '@/graphql/queries'
import ProgrammeEntryThumb from '@/components/programme/ProgrammeEntryThumb'
import CollectionItemThumb from '@/components/collection/CollectionItemThumb'
export default {
  name: 'Search',
  data () {
    return {
      pages: null,
      sections: ['programme', 'collection']
    }
  },
  computed: {
    term () {
      return decodeURIComponent(this.$route.params.term)
    }
  },
  methods: {
    async search () {
      if (!this.term) return console.error('missing search term')
      try {
        // search!
        const resp = await api(query, { term: this.term })

        // handle
        if (!this.pages) {
          this.pages = []
        }

        const page = this.groupPageByResults(resp.data.entries)

        console.log(resp, page)

        this.pages.push(page)
      } catch (e) {
        console.error('Search:', e)
      }
    },
    isProgram ({ section }) {
      return ['exhibitions', 'performances', 'residencies', 'podcasts', 'projects'].includes(section)
    },
    groupPageByResults (entries) {
      return {
        programme: entries.filter(entry => this.isProgram(entry)),
        collection: entries.filter(entry => entry.section === 'collection')
      }
    }
  },
  created () {
    this.search()
  },
  mounted () {
    // this.$el.focus()
  },
  metaInfo () {
    const title = this.term ? `Search for "${this.term}"` : 'Search'
    return {
      title,
      meta: this.$store.getters.meta({ title })
    }
  },
  components: { ProgrammeEntryThumb, CollectionItemThumb }
}

const query = `
  query Search ($term: String!) {
    entries (section: ["collection", "exhibitions", "performances", "residencies", "projects", "podcasts"], limit: 100, search: $term) {
      title
      slug
      section: sectionHandle

      # programme entry
      ${programmeEntryItem}

      # collection entry
      ... on collection_collection_Entry {
        # artistSort
        artist
        year
        indexImage: collectionIndexImage {
          url
          ... on images_Asset {
            width
            height
            altText
          }
        }
        slides: slidesCollectionEntry (limit: 1) {
          ... on slidesCollectionEntry_slide_BlockType {
            id
            image {
              ... on images_Asset {
                title
                altText
                # assetCaption
                # courtesy
                # copyright
                # photoCredit
              }
              url
              width
              height
            }
          }
        }
      }
    }
  }
`
</script>

<style>
</style>
